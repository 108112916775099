* {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
}

:root {
  font-synthesis: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --rnib-pink: #e40474;
  --rnib-darkPink: #990b4f;
  --rnib-blue: #0091b5;
  --rnib-yellow: #fdc301;
  --rnib-transparent-grey: #ffffff85;
  --rnib-transparent-white: #ffffffe6;
  font-family: Arial, Helvetica Neue, Helvetica, Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.introduction button {
  padding: .6rem;
  font-weight: bold;
}

a {
  color: var(--rnib-blue);
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  font-weight: 500;
}

a:hover {
  color: var(--rnib-blue);
}

button.hidden {
  opacity: 0;
  pointer-events: none;
}

button:disabled {
  pointer-events: none;
  opacity: .2;
}

body {
  height: 100%;
  min-width: 320px;
  min-height: 100vh;
  background: #fff;
  place-items: center;
  margin: 0;
  display: block;
}

canvas[data-engine^="three.js"] {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

footer > p:first-child {
  text-align: start;
}

footer > p:last-child {
  text-align: end;
}

h1 {
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: .25em;
  border-bottom: .4rem solid var(--rnib-pink);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: 3em;
  line-height: 1.1;
}

h2 {
  font-weight: 500;
}

h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  width: -moz-fit-content;
  width: fit-content;
  font-size: .75rem;
}

.introduction {
  max-height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 1rem 2rem;
  display: flex;
  overflow: hidden;
}

.introduction.hidden {
  display: none;
}

#RNIB__title {
  letter-spacing: .4rem;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: .2rem solid var(--rnib-pink);
}

#RNIB__subtitle {
  margin: .3rem 0;
}

.introduction__body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  border-left: 1.5rem solid var(--rnib-pink);
  color: #fff;
  background-color: #000;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  display: flex;
}

.introduction__body p {
  line-height: 1.4;
}

.introduction__body > div {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.introduction__body img {
  width: 100%;
  padding-top: 2rem;
}

.introduction_img-container {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  position: relative;
}

.introduction__button-container {
  height: -moz-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: start;
  gap: 0 1rem;
  margin-top: 0;
  margin-left: 0;
  padding: 1rem 0;
  display: flex;
}

#yes-controller-button {
  height: -moz-fit-content;
  height: fit-content;
  width: 6rem;
  background-color: var(--rnib-pink);
  color: #fff;
  justify-content: space-between;
  display: flex;
}

#yes-controller-button:after {
  content: "✔";
  display: block;
  transform: scale(1);
}

#no-controller-button {
  height: -moz-fit-content;
  height: fit-content;
  width: 6rem;
  color: #000;
  background-color: #fff;
  justify-content: space-between;
  display: flex;
}

#no-controller-button:after {
  content: "+";
  font-weight: 100;
  line-height: 1.35rem;
  display: flex;
  transform: rotate(45deg)scale(2);
}

#skip-headset-button {
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--rnib-pink);
  color: #fff;
  justify-content: space-between;
  display: flex;
}

#skip-headset-button:after {
  content: "";
  width: 2rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  transform: scale(1);
}

.look-down-container.hidden {
  display: none;
}

#look-here-button {
  color: #fff;
  text-transform: uppercase;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--rnib-pink);
  width: 14rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  padding: .5rem;
  font-size: 2rem;
  display: flex;
}

.eye-seperation-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.eye-seperation {
  width: 25rem;
  margin: 4rem 0;
}

.eye-seperation-inner {
  background: var(--rnib-transparent-grey);
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2rem;
  display: flex;
}

.eye-seperation__header p, .severity-scale__header p {
  padding: 1rem 1rem 1rem .2rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2rem;
}

.eye-seperation__slider-container {
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.eye-seperation__slider {
  position: relative;
}

.eye-seperation__slider:before {
  z-index: 5;
  content: "";
  height: 1rem;
  width: 100%;
  background: #000;
  border-radius: 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#eye-seperation-progress {
  z-index: 6;
  content: "";
  height: 1rem;
  width: 50%;
  background: var(--rnib-pink);
  border-radius: 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#eye-seperation-minus-button, #eye-seperation-plus-button {
  height: -moz-fit-content;
  height: fit-content;
  color: #fff;
  background-color: var(--rnib-pink);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
}

#eye-seperation-continue-button {
  width: 100%;
  max-width: 16rem;
  margin: 0 auto;
  font-size: 1.5rem;
}

.eye-seperation__slider {
  width: 100%;
}

#eye-seperation-value {
  z-index: 7;
  color: #fff;
  width: 3rem;
  height: 3rem;
  background-color: #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.eye-seperation-container.hidden {
  display: none;
}

#find-out-more-button {
  width: 13rem;
  margin: .5rem 0;
  padding: .5rem;
}

#donate-button {
  width: 13rem;
  margin: .5rem .5rem 1rem;
  padding: .8rem;
}

#main-ui-donate-button {
  padding: 1.2rem;
}

#find-out-more-button, #main-ui-find-out-more-button {
  color: #fff;
  background: #000;
  align-items: center;
  gap: 1rem;
  display: flex;
}

#main-ui-find-out-more-button {
  justify-content: space-between;
  padding: 1.5rem;
}

#donate-button {
  color: #fff;
  background: #000;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 2rem;
  display: flex;
}

#continue-button {
  height: -moz-fit-content;
  height: fit-content;
  color: #000;
  background: #fff;
  justify-content: space-between;
  display: flex;
}

#continue-button:after {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  color: #000;
  background-image: url("ContinueIcon.42aa6ade.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: .2rem;
  font-size: 1.5rem;
  line-height: .3rem;
  display: block;
}

#task-mode__yes-button, #task-mode__no-button, #main-ui-home-button, #main-ui-close-button, #main-ui-donate-button, #main-ui-eye-condition-button, #main-ui-replay-button, #main-ui-scenario-button, #task-mode__continue-button, #task-mode__sound-button {
  min-width: 100px;
  justify-content: space-between;
  display: flex;
}

#main-ui-eye-condition-button.hidden, #main-ui-close-button.hidden, #main-ui-replay-button.hidden, #main-ui-scenario-button.hidden {
  display: none;
}

.rnib-branding {
  z-index: 5;
  flex-direction: column;
  display: flex;
  position: relative;
}

.rnib-branding:before {
  content: "";
  height: auto;
  width: 60%;
  border-bottom: 10px solid var(--rnib-yellow);
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: -50px;
  display: inline-block;
}

.rnib-branding:after {
  content: "";
  height: auto;
  width: 65%;
  border-bottom: 10px solid var(--rnib-pink);
  margin-top: 10px;
  margin-left: -50px;
  display: inline-block;
}

.reticles {
  pointer-events: none;
  text-align: center;
  color: #fff;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 100;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
}

.reticles--point {
  pointer-events: none;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.reticles--point:after {
  content: "";
  width: .3rem;
  height: .3rem;
  background-image: url("WhiteDot.a7ff6c9b.svg");
  background-size: contain;
  display: block;
}

.reticles.selecting .reticles--point:after {
  width: 5rem;
  height: 5rem;
  background-image: url("loader.2f232bdb.svg");
}

.reticles--point.left {
  transform: translateX(.2rem);
}

.reticles--point.right {
  border-left: 2px solid #000;
  transform: translateX(-.2rem);
}

button {
  box-sizing: border-box;
  border: none;
}

.button {
  touch-action: manipulation;
  color: #fff;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #000;
  border: 1px solid #0000;
  justify-content: space-between;
  align-items: center;
  padding: .6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  transition: border-color .25s;
  display: flex;
}

button.hidden {
  display: none !important;
}

.ui {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.ui.hidden {
  visibility: hidden;
  pointer-events: none;
}

.reticles {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#tutorial {
  height: -moz-fit-content;
  height: fit-content;
  width: 30rem;
  background-color: #0000;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#look-here-button {
  margin-top: 10rem;
}

.tutorial__main-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.tutorial__look-down {
  min-width: 750px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
}

.tutorial__look-down span {
  background-color: var(--rnib-transparent-grey);
  padding: .3rem 1rem;
}

.look-down-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.down-eye-icon {
  width: 4.4rem;
  height: auto;
  margin-bottom: 1rem;
  transform: translateY(30%);
}

.down-arrow-icon {
  height: 15rem;
}

#homepage {
  background-color: var(--rnib-transparent-grey);
  max-height: 90%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2rem;
  padding: 1rem 2rem;
}

#homepage header {
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem 0;
  display: flex;
}

.home__button-container {
  width: 100%;
  flex-direction: column;
  justify-content: start;
  display: flex;
}

.home__button-container button {
  width: 40%;
  justify-content: start;
  padding: 1rem;
  font-size: 2rem;
}

.home__top-button-container, .home__bottom-button-container {
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  display: flex;
}

#homepage button {
  color: #fff;
  background-color: #000;
}

.homepage-grid {
  width: 24rem;
  height: 28rem;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5rem;
  display: flex;
}

.homepage-grid div {
  height: 100%;
  width: 50%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.homepage-grid button {
  text-align: left;
  width: 10rem;
  height: 10rem;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: start;
  align-items: start;
  padding: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
}

#homepage .a {
  background-image: url("EyeConditionsIconButton.36782e9e.svg");
  background-position: 70% 150%;
  background-color: var(--rnib-pink);
}

#homepage .b {
  background-image: url("360VideoIconButton.3bf6e48c.svg");
  background-color: var(--rnib-blue);
  height: 13rem;
  display: none;
}

#homepage .c {
  background-image: url("TaskModeIconButton.13814556.svg");
  background-color: var(--rnib-blue);
  height: 13rem;
  display: none;
}

#homepage .d {
  background-image: url("SurveyIconButton.37e6fdb9.svg");
  background-position: 70% 85%;
  background-size: 80%;
  background-color: var(--rnib-pink);
}

#external-site {
  width: 20rem;
  height: auto;
  flex-direction: column;
  justify-content: center;
}

.external__text-box {
  background-color: var(--rnib-transparent-white);
  flex-direction: column;
  place-content: start space-between;
  display: flex;
}

.external-site-title {
  width: 100%;
  height: 50%;
  flex-direction: row;
  display: flex;
}

.external-site-title h6 {
  width: 90%;
  text-align: center;
  padding-top: 1.2rem;
  font-size: .95rem;
  font-weight: bold;
}

.external-site-left-tab {
  background-color: var(--rnib-pink);
  width: 5%;
}

.external__text-box p {
  width: 100%;
  text-align: center;
  align-items: center;
  padding-bottom: 1.2rem;
  font-size: .95rem;
}

.external-site-text {
  text-align: center;
  flex-direction: column;
  margin-right: 3rem;
  padding-left: 1rem;
  display: flex;
}

#external-site-confirm-button, #external-site-back-button {
  border: none;
  padding: 0;
}

.external__button-container {
  width: 100%;
  white-space: nowrap;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
  display: grid;
}

#external-site-confirm-button span, #external-site-back-button span {
  padding: .6em 1.2em;
}

#external-site-back-button:before {
  content: "<";
  height: 100%;
  color: #fff;
  background-color: var(--rnib-blue);
  width: 3rem;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

#external-site-confirm-button:before {
  content: "✓";
  height: 100%;
  color: #fff;
  background-color: var(--rnib-pink);
  width: 3rem;
  border: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ui > div {
  display: none;
}

.ui > .active {
  display: flex !important;
}

#gui {
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#gui button.cta {
  width: 25rem;
}

#gui button {
  background-color: var(--rnib-pink);
  color: #fff;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  font-size: 2rem;
  display: flex;
}

.ui.headset-mode #gui {
  display: flex;
}

.ui.headset-mode {
  z-index: -10;
  position: absolute;
}

.ui.headset-mode > div {
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.ui.headset-mode .eye-conditions-grid, .ui.headset-mode .task-mode-grid {
  background-color: var(--rnib-transparent-grey);
}

.tooltip {
  max-width: 250px;
  border-left: 30px solid var(--rnib-pink);
  color: #000;
  background-color: #fff;
  margin: .8rem;
  padding: .5rem;
  font-weight: 700;
  position: relative;
}

#tooltip-expand-button {
  content: "i";
  color: #fff;
  background-color: var(--rnib-pink);
  z-index: 6;
  width: 2.5rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.tooltip.unclickable:after {
  display: none;
}

.tooltip.expanded-info:after {
  display: none !important;
}

.tooltip.taskMode {
  border-left: 20px solid var(--rnib-blue);
}

#tooltip-main-button.hidden + #tooltip-expand-button, #tooltip-main-button.expanded-info + #tooltip-expand-button, #tooltip-main-button.unclickable + #tooltip-expand-button {
  display: none;
}

.tooltip.hidden {
  visibility: hidden;
}

.tooltip-expanded-container {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.rnib-expanded-info span {
  max-width: 700px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

.rnib-expanded-info.hidden {
  display: none;
}

.collapse-info {
  color: #fff;
  background-color: var(--rnib-pink);
  border-radius: 50%;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(-90deg);
}

.eye-conditions-grid, .task-mode-grid {
  display: flex;
  overflow: hidden;
}

.task-mode-grid.hidden {
  display: none;
}

.eye-conditions-grid div, .task-mode-grid div {
  height: 100%;
  flex-shrink: 0;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

#main {
  box-sizing: border-box;
  height: 100%;
  color: #000;
  width: 100%;
  background-color: #0000;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  top: 0;
  left: 0;
}

#main.space-between {
  justify-content: space-between;
}

#main.center {
  justify-content: center;
}

#main.end {
  justify-content: end;
}

.eye-conditions {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.eye-conditions-container {
  width: 100%;
  flex-direction: column;
  padding: 0 2rem;
  display: flex;
}

.eye-conditions-container.hidden {
  display: none;
}

.severity-scale-inner {
  width: 100%;
  flex-direction: column;
  flex-direction: flex-start;
  background-color: var(--rnib-transparent-grey);
  display: flex;
}

#severity-scale-container.hidden, .severity-scale__header.hidden, .severity-scale__footer.hidden, .color-blindness-grid.hidden, .severity-scale.hidden {
  display: none;
}

.severity-scale__footer {
  width: 80%;
  box-sizing: border-box;
  justify-content: space-between;
  place-self: center;
  padding: 0 0 1rem;
  font-weight: normal;
  display: flex;
}

severity-scale__header:before {
  width: 1.5rem;
}

severity-scale__header {
  padding-right: 1rem;
  font-weight: bold;
  display: flex;
}

.color-blindness-grid {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem;
  display: grid;
}

.left-tab {
  gap: 1rem;
  display: flex;
}

.left-tab:before {
  content: "";
  height: auto;
  width: 2rem;
  background-color: var(--rnib-pink);
  display: block;
}

.severity-scale {
  box-sizing: border-box;
  width: 80%;
  justify-content: space-between;
  align-self: center;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 0;
  display: flex;
}

.severity-scale li {
  list-style-type: none;
}

.severity-scale li button {
  background-color: var(--rnib-pink);
  color: #fff;
  padding: .5rem .8rem;
  font-size: 1.5rem;
  font-weight: bold;
  list-style-type: none;
}

.color-blindness-grid button {
  text-transform: capitalize;
  background-color: var(--rnib-pink);
  color: #fff;
  padding: .5rem .8rem;
  font-size: 1.2rem;
  font-weight: bold;
  list-style-type: none;
}

.ui.headset-mode > #homepage {
  z-index: -105;
  position: absolute;
}

.ui.headset-mode > #main {
  position: absolute;
}

.navigation {
  width: 110%;
  justify-content: space-between;
  margin-left: -25px;
  display: flex;
  position: absolute;
  bottom: 250px;
}

.navigation--arrow.left {
  width: 0;
  height: 0;
  background: none;
  border-top: 40px solid #0000;
  border-bottom: 40px solid #0000;
  border-right: 40px solid #1a1a1a;
}

.navigation--arrow.right {
  width: 0;
  height: 0;
  background: none;
  border-top: 40px solid #0000;
  border-bottom: 40px solid #0000;
  border-left: 40px solid #1a1a1a;
}

.navigation--arrow:disabled {
  pointer-events: none;
  opacity: .2;
}

.eye-conditions-grid .cta, .task-mode-grid .cta {
  height: 8rem;
  background-color: var(--rnib-pink);
  text-align: left;
  justify-content: center;
  align-items: center;
  margin: .5rem;
  font-size: .8rem;
  display: flex;
}

.cta {
  color: #fff;
  font-weight: 700;
}

.navigation--arrow .right {
  width: 0;
  height: 0;
  border-top: 60px solid #0000;
  border-bottom: 60px solid #0000;
  border-left: 60px solid #1a1a1a;
  border-radius: 0;
}

#canvas-texture {
  visibility: hidden;
  max-height: 1vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

button:hover {
  border-color: var(--rnib-blue);
}

.dg.ac, .stats_modal_container > div {
  padding: 1rem 2rem;
  display: none;
}

.handPoint {
  animation-name: handPointAnim;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: forwards;
  animation-iteration-count: infinite;
}

@keyframes handPointAnim {
  0% {
    margin-top: 0;
    margin-left: 0;
  }

  50% {
    margin-top: 15%;
    margin-left: 15%;
  }

  100% {
    margin-top: 0;
    margin-left: 0;
  }
}

@media screen and (orientation: landscape) {
  .dg.ac, .stats_modal_container > div {
    display: none;
  }

  .button {
    min-width: 95px;
  }

  .stats_modal_container {
    display: block;
  }

  .introduction__body {
    height: 62vh;
    grid-template-columns: 1fr 1fr;
    display: grid;
    position: relative;
  }

  .introduction__button-container {
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 1rem;
  }

  .introduction__button-container .button {
    width: 10rem;
  }

  .introduction__body_information div {
    max-width: 40vw;
    position: absolute;
  }

  .introduction_img-container {
    margin-top: 0;
    margin-left: auto;
  }

  .introduction__body img {
    object-fit: contain;
    max-height: 50vh;
    object-fit: contain;
    padding-top: 0;
    transform: translateX(36px);
  }

  .ui {
    width: -moz-fit-content;
    width: fit-content;
    pointer-events: none;
    display: block;
  }

  #homepage {
    max-height: none;
    width: -moz-fit-content;
    width: fit-content;
    height: unset;
    padding: 0;
  }

  .main .home__button-container {
    padding: 0 5rem;
  }

  #homepage .b, #homepage .c {
    display: flex;
  }

  #main {
    width: 1000px;
    height: 44rem;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;
  }

  .navigation--arrow.left, .navigation--arrow.right {
    color: #fff;
    background-color: #1a1a1a;
    border: none;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: .5rem;
    padding: 3rem;
    font-size: 3rem;
    font-weight: bold;
    display: flex;
  }

  .navigation--arrow.left:before {
    content: "<";
    display: block;
  }

  .navigation--arrow.right:after {
    content: ">";
    display: block;
  }

  .rnib-expanded-info {
    min-width: 500px;
  }

  #homepage {
    margin: 0;
  }

  .tooltip {
    max-width: 500px;
    padding: .5rem 2rem;
    font-size: 1.7rem;
  }

  .eye-conditions-container.hidden {
    display: none;
  }

  .eye-conditions-grid div {
    height: 100%;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(3, 230px);
    gap: .5rem;
    display: grid;
  }

  #myAudio {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .task-mode-grid div {
    height: 100%;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(2, 253px);
    gap: .5rem;
    display: grid;
  }

  .task-mode__subtitles {
    width: 100%;
    color: #fff;
    -webkit-text-stroke: 1.2px black;
    filter: drop-shadow(0 2.5px #000);
    text-align: center;
    justify-content: space-around;
    font-size: 1.5rem;
    font-weight: bold;
    display: none;
  }

  .task-mode__subtitles.active {
    display: flex;
  }

  .severity-scale li button {
    padding: .7rem 1rem;
  }

  #severity-scale-container {
    width: 24rem;
    background-attachment: red;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .navigation--arrow {
    width: 2rem;
    height: 2rem;
    margin: 2rem;
  }

  .eye-conditions-grid .cta, .task-mode-grid .cta {
    height: 10rem;
    justify-content: start;
    align-items: start;
    padding: 1rem;
    font-size: 1rem;
  }
}

@media screen and (orientation: portrait) {
  .introduction__body {
    min-height: 65vh;
  }

  .introduction__button-container {
    justify-content: end;
    margin-top: auto;
  }

  #continue-button {
    width: 8rem;
  }

  .home__button-container .button:after {
    width: 1rem !important;
    height: 1rem !important;
    margin-right: 0 !important;
  }

  #task-mode__no-button:after {
    width: auto;
    height: auto;
  }

  .tooltip-expanded-container, .tooltip {
    width: 100%;
  }

  .tooltip-expand-button {
    transform: translate(50%, -50%);
  }

  .rnib-expanded-info {
    margin: 1rem;
  }

  .rnib-expanded-info span {
    max-height: 80vh;
    overflow: scroll;
  }

  #homepage-grid .d {
    background-position: 163% 63%;
  }

  #main {
    padding: 0;
  }

  .eye-conditions-grid .cta {
    text-align: center;
    padding: .5rem;
  }

  #external-site {
    margin: 0;
  }

  #external-site main {
    margin: 1rem;
  }

  #main-ui-eye-condition-button {
    position: relative;
  }

  #main-ui-eye-condition-button:after {
    position: absolute;
    right: .5rem;
  }
}

.button-icon {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1.5em;
}

.button-icon[data-icon="skip"] {
  background-image: url("SkipIcon.74e1f970.svg");
}

.button-icon[data-icon="continue"] {
  background-image: url("ContinueIconWhite.4a5fb812.png");
}

.button-icon[data-icon="recenter"] {
  background-image: url("RecentreARIcon.e06ba5b2.svg");
}

.button-icon[data-icon="donate"] {
  background-image: url("HeartIcon.39c67ee8.png");
}

.button-icon[data-icon="sound-on"] {
  background-image: url("SoundOnIcon.4cfcf477.svg");
}

.button-icon[data-icon="sound-off"] {
  background-image: url("SoundOffIcon.ecb60b9d.svg");
}

.button-icon[data-icon="explore"] {
  background-image: url("Magnifier.ac98c4fb.svg");
}

.button-icon[data-icon="home"] {
  background-image: url("HomeIcon.122bf84c.png");
}

.button-icon[data-icon="scenario"] {
  background-image: url("ScenarioIcon.76dbca5b.svg");
}

.button-icon[data-icon="eye"] {
  background-image: url("EyeIcon.219c09a1.svg");
}

.button-icon[data-icon="replay"] {
  background-image: url("ReplayIcon.5d4883f0.svg");
}

.button-icon[data-icon="tick"] {
  background-image: url("ConfirmIcon.ad947452.svg");
}

.button-icon[data-icon="cross"] {
  background-image: url("closeIcon.783275a8.svg");
}

#eye-seperation-tooltip {
  width: 65%;
  font-size: 1.1rem;
  line-height: 1.2rem;
}

#tutorial {
  width: 350px;
  padding: 1rem;
  font-size: 1.2rem;
}

#gaze-control-tooltip {
  width: 85%;
  padding: 1rem;
  font-size: 1.2rem;
}

.button-recenter h4 {
  font-size: 1.8rem;
}

.main_footer {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.severity-scale button.selected {
  background-color: var(--rnib-darkPink);
}

/*# sourceMappingURL=index.b7b3e8f1.css.map */
