* {
  margin: 0;
  padding: 0;

  user-select: none;
}

/* html {
  position: fixed;
  overflow: hidden;
} */

/* (don't load fonts as it slows down the renderer)

@font-face {
  font-family: Arial;
  src: url(../public/assets/fonts/Arial.ttf);
}

@font-face {
  font-family: Arial;
  src: url(../public/assets/fonts/Arial\ Bold.ttf);
  font-weight: bold;
}
*/

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

:root {
  font-family: Arial, "Helvetica Neue", Helvetica, Roboto, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);

  /* Colors */
  --rnib-pink: #E40474;
  --rnib-darkPink: rgb(153, 11, 79);
  --rnib-blue: #0091B5;
  --rnib-yellow: #FDC301;
  --rnib-transparent-grey: #ffffff85;
  --rnib-transparent-white: rgba(255, 255, 255, 0.9);
}

.introduction button {
  font-weight: bold;
  padding: 0.6rem;
}

a {
  font-weight: 500;
  color: var(--rnib-blue);
  text-decoration: inherit;
}

a:hover {
  color: var(--rnib-blue);
}

button.hidden {
  opacity: 0;
  pointer-events: none;
}

button:disabled {
  pointer-events: none;
  opacity: 20%;
}

body {
  margin: 0;
  display: block;
  height: 100%;
  background: white;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

canvas[data-engine^="three.js"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

footer>p:first-child {
  text-align: start;
}

footer>p:last-child {
  text-align: end;
}

h1 {
  width: fit-content;
  letter-spacing: 0.25em;
  border-bottom: 0.4rem solid var(--rnib-pink);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-size: 3em;
  line-height: 1.1;
}

h2 {
  font-weight: 500;
}

h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  width: fit-content;
  font-size: 0.75rem;
}

/* Intro Page */

.introduction {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  margin: 0;
  overflow: hidden;
  max-height: 100vh;
}

.introduction.hidden {
  display: none;
}

/* Header */

#RNIB__title {
  letter-spacing: 0.4rem;
  width: fit-content;
  border-bottom: 0.2rem solid var(--rnib-pink);
}

#RNIB__subtitle {
  margin: 0.3rem 0;
}

/* Body */

.introduction__body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: black;
  border-left: 1.5rem solid var(--rnib-pink);
  padding: 1.5rem 1rem;
  color: white;
}

.introduction__body p {
  line-height: 1.4;
}

.introduction__body>div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.introduction__body img {
  padding-top: 2rem;
  width: 100%;
}

/* .introduction__body > section {
      display: none;
    } */

.introduction_img-container {
  margin-left: auto;
  position: relative;
  width: fit-content;
}

.introduction__button-container {
  gap: 0;
  margin-top: 0;
  margin-left: 0;
}

/* Buttons */

.introduction__button-container {
  height: fit-content;
  padding: 1rem 0;
  display: flex;
  column-gap: 1rem;
  justify-content: start;
}

#yes-controller-button {
  height: fit-content;
  width: 6rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--rnib-pink);
  color: white;
}

#yes-controller-button::after {
  content: "✔";
  display: block;
  transform: scale(1);
}

#no-controller-button {
  height: fit-content;
  width: 6rem;
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: black;
}

#no-controller-button::after {
  line-height: 1.35rem;
  content: "+";
  transform: rotate(45deg) scale(2);
  display: flex;
  font-weight: 100;
}

#skip-headset-button {
  height: fit-content;
  display: flex;
  justify-content: space-between;
  background-color: var(--rnib-pink);
  color: white;
}

#skip-headset-button::after {
  content: "";
  /* background-image: url("../public/assets/svg/SkipHeadsetButton.svg"); */
  display: block;
  transform: scale(1);
  width: 2rem;
  height: 1rem;
  background-repeat: no-repeat;
  content: "";
  background-size: contain;
}


.look-down-container.hidden {
  display: none;
}


#look-here-button {
  font-size: 2rem;
  color: white;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  height: fit-content;
  background-color: var(--rnib-pink);
  width: 14rem;
  margin-bottom: 2rem;
}


/* Eye Seperation */

.eye-seperation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eye-seperation {
  width: 25rem;
  margin: 4rem 0;
}

.eye-seperation-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--rnib-transparent-grey);
  padding-bottom: 2rem;
}


/* 
.eye-seperation__header {
  font-weight: bold;
  line-height: 2rem;
  font-size: 1.5rem;

} */

.eye-seperation__header p,
.severity-scale__header p {
  padding: 1rem;
  padding-left: 0.2rem;
  font-weight: bold;
  line-height: 2rem;
  font-size: 1.4rem;
}

.eye-seperation__slider-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.eye-seperation__slider {
  position: relative;
}

.eye-seperation__slider::before {
  z-index: 5;
  transform: translateY(-50%);
  content: "";
  top: 50%;
  left: 0;
  height: 1rem;
  position: absolute;
  width: 100%;
  background: black;
  border-radius: 1rem;
}

#eye-seperation-progress {
  z-index: 6;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 1rem;
  position: absolute;
  width: 50%;
  background: var(--rnib-pink);
  border-radius: 1rem;
}

#eye-seperation-minus-button,
#eye-seperation-plus-button {
  height: fit-content;
  color: #fff;
  background-color: var(--rnib-pink);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
}

#eye-seperation-continue-button {
  width: 100%;
  max-width: 16rem;
  margin: 0 auto;
  font-size: 1.5rem;
}

/* Eye Seperation Slider */

.eye-seperation__slider {
  width: 100%;
}

#eye-seperation-value {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 7;
  color: #fff;
  background-color: black;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  width: 3rem;
  height: 3rem;
}

.eye-seperation-container.hidden {
  display: none;
}

/* Main */

#find-out-more-button {
  width: 13rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
}

#donate-button {
  width: 13rem;
  padding: 0.8rem;
  margin: 0.5rem;
  margin-bottom: 1rem;
}

#main-ui-donate-button {
  padding: 1.2rem;
}

#find-out-more-button,
#main-ui-find-out-more-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

#main-ui-find-out-more-button {
  justify-content: space-between;
  padding: 1.5rem;
}

#donate-button {
  font-size: 1rem;
  display: flex;
  line-height: 2rem;
  align-items: center;
  justify-content: space-between;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

/*
#donate-button::after,
#main-ui-donate-button::after {
  content: "";
  width: 1.9rem;
  height: 2rem;
  margin-right: 0.5rem;
  background-image: url(../public/assets/images/HeartIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
}
*/

#continue-button {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  background: white;
  color: black;
}

#continue-button::after {
  content: "";
  background-image: url(../public/assets/images/ContinueIcon.png);
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.1rem;
  height: 1.1rem;
  color: black;
  font-size: 1.5rem;
  margin-left: 0.2rem;
  line-height: 0.3rem;
}

/*
#continue-button::after,
#task-mode__continue-button::after,
#eye-seperation-continue-button::after {
  content: "";
  background-image: url(../public/assets/images/ContinueIcon.png);
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.1rem;
  height: 1.1rem;
  color: black;
  font-size: 1.5rem;
  margin-left: 0.2rem;
  line-height: 0.3rem;
}

#task-mode__continue-button::after,
#eye-seperation-continue-button::after {
  width: 2rem;
  height: 2rem;
  background-image: url(../public/assets/images/ContinueIconWhite.png);
}
*/
#task-mode__yes-button,
#task-mode__no-button,
#main-ui-home-button,
#main-ui-close-button,
#main-ui-donate-button,
#main-ui-eye-condition-button,
#main-ui-replay-button,
#main-ui-scenario-button,
#task-mode__continue-button,
#task-mode__sound-button {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
}

#main-ui-eye-condition-button.hidden,
#main-ui-close-button.hidden,
#main-ui-replay-button.hidden,
#main-ui-scenario-button.hidden {
  display: none;
}

/*
#main-ui-home-button::after {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../public/assets/svg/HomeIcon.png");
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}

#main-ui-scenario-button::after {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../public/assets/svg/ScenarioIcon.svg");
  width: 3.5rem;
  height: 2rem;
}
*/

/*
#task-mode__sound-button::after {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../public/assets/svg/SoundOnIcon.svg");
  width: 2rem;
  height: 2rem;
}

#task-mode__sound-button.soundOff::after {
  background-image: url("../public/assets/svg/SoundOffIcon.svg");
}


#main-ui-eye-condition-button::after {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../public/assets/svg/EyeIcon.svg");
  width: 3.5rem;
  height: 2rem;
  margin-right: 1rem;
}

#main-ui-replay-button::after {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../public/assets/svg/ReplayIcon.svg");
  width: 3.5rem;
  height: 2rem;
}
*/

/*AR User Interface*/

.rnib-branding {
  z-index: 5;
  display: flex;
  flex-direction: column;
  position: relative;
}

.rnib-branding::before {
  display: inline-block;
  content: "";
  height: auto;
  width: 60%;
  margin-right: -50px;
  margin-left: auto;
  border-bottom: 10px solid var(--rnib-yellow);
  margin-bottom: 10px;
}

.rnib-branding::after {
  display: inline-block;
  content: "";
  height: auto;
  width: 65%;
  margin-top: 10px;
  margin-left: -50px;
  border-bottom: 10px solid var(--rnib-pink);
}

.reticles {
  pointer-events: none;
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 100;
  color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reticles--point {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.reticles--point::after {
  content: "";
  display: block;
  background-size: contain;
  background-image: url("../public/assets/svg/WhiteDot.svg");
  width: 0.3rem;
  height: 0.3rem;
}

.reticles.selecting .reticles--point::after {
  background-image: url("../public/assets/svg/loader.svg");
  width: 5rem;
  height: 5rem;
}

.reticles--point.left {
  transform: translateX(0.2rem);
}

.reticles--point.right {
  border-left: 2px solid black;
  transform: translateX(-0.2rem);
}

button {
  border: none;
  box-sizing: border-box;
}

.button {
  display: flex;
  touch-action: manipulation;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  /* font-family: Arial; */
  color: white;
  background: black;
  cursor: pointer;
  transition: border-color 0.25s;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

button.hidden {
  display: none !important;
}

.ui {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui.hidden {
  visibility: hidden;
  pointer-events: none;
}

.reticles {
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Tutorial Page */

#tutorial {
  height: fit-content;
  width: 30rem;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#look-here-button {
  margin-top: 10rem;
}



.tutorial__main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutorial__look-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 750px;
  font-weight: bold;
  font-size: 2rem;
}

.tutorial__look-down span {
  padding: 0.3rem 1rem;

  background-color: var(--rnib-transparent-grey);
}

/* .tutorial__look-down::before {
    content: '';
    display: block;
    background-color: transparent;
    background-image: url('../public/assets/svg/DownEyeIcon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 6rem;
    height: 4rem;
  }

  .tutorial__look-down::after {
    content: '';
    display: block;
    background-color: transparent;
    background-image: url('../public/assets/svg/DownArrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 8rem;
    height: 8rem;
  } */

.look-down-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.down-eye-icon {
  width: 4.4rem;
  margin-bottom: 1rem;
  height: auto;
  transform: translateY(30%);
}

.down-arrow-icon {
  height: 15rem;
}

/* Homepage */

#homepage {
  flex-direction: column;
  align-items: center;
  background-color: var(--rnib-transparent-grey);
  padding: 1rem 2rem;
  margin: 2rem;
  max-height: 90%;
  width: 100%;
  justify-content: space-between;
}

#homepage header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 6rem 0 6rem;
}



.home__button-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.home__button-container button {
  justify-content: start;
  font-size: 2rem;
  width: 40%;
  padding: 1rem;
}


.home__top-button-container,
.home__bottom-button-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
}


/* .home__top-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  justify-content: center;
  white-space: nowrap;
  box-sizing: border-box;
  justify-self: center;
  flex-grow: 1;

  column-gap: 5rem;
  row-gap: 2rem;
  margin-top: 1rem;

  gap: 1rem;



}

.home__top-button-container button {
  font-size: 2rem;
  width: 40%;
  flex-grow: 1;
} */

/* .home__bottom-button-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
} */


#homepage button {
  background-color: black;
  color: white;
}

/* .homepage-grid {
  /* display: flex; */
/*display: grid;
  flex-direction: column;
   grid-template-rows: 1fr 1fr;
  grid-template-rows: repeat(9, 1rem);
  grid-auto-flow: column;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 1rem 4rem;
  display: grid; */


/*width: 500px;
  height: 600px;

} */



/* .homepage-grid .cta {
  display: flex;
  justify-content: start;
  align-items: start;
  text-align: left;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;500px
  min-height: 5rem;
} */

.homepage-grid {
  display: flex;
  flex-direction: row;
  width: 24rem;
  height: 28rem;
  justify-content: space-between;
  margin: 0rem 5rem;
}

.homepage-grid div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 50%;
}

.homepage-grid button {
  display: flex;
  justify-content: start;
  align-items: start;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  padding: 1rem;
  width: 10rem;
  height: 10rem;
}


#homepage .a {
  background-position: 70% 150%;
  background-image: url("../public/assets/svg/EyeConditionsIconButton.svg");
  background-color: var(--rnib-pink);
}

#homepage .b {
  display: none;
  background-image: url("../public/assets/svg/360VideoIconButton.svg");
  background-color: var(--rnib-blue);
  height: 13rem;

}

#homepage .c {
  display: none;
  background-image: url("../public/assets/svg/TaskModeIconButton.svg");
  background-color: var(--rnib-blue);
  height: 13rem;
}

#homepage .d {
  background-position: 70% 85%;
  background-size: 80%;
  background-image: url("../public/assets/svg/SurveyIconButton.svg");
  background-color: var(--rnib-pink);
}

/* External Site */

#external-site {
  flex-direction: column;
  justify-content: center;
  width: 20rem;
  height: auto;
}

.external__text-box {
  display: flex;
  flex-direction: column;
  background-color: var(--rnib-transparent-white);
  justify-content: space-between;
  align-content: start;
}


.external-site-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50%;
}

.external-site-title h6 {
  width: 90%;
  font-size: 0.95rem;
  text-align: center;
  font-weight: bold;
  padding-top: 1.2rem;
}

.external-site-left-tab {
  background-color: var(--rnib-pink);
  width: 5%;

}

.external__text-box p {
  width: 100%;
  text-align: center;
  font-size: 0.95rem;
  align-items: center;
  padding-bottom: 1.2rem;
}


.external-site-text {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  margin-right: 3rem;
  text-align: center;
}


#external-site-confirm-button,
#external-site-back-button {
  border: none;
  padding: 0;
}

.external__button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;
  box-sizing: border-box;
  gap: 1rem;
  margin-top: 1rem;
}

#external-site-confirm-button span,
#external-site-back-button span {
  padding: 0.6em 1.2em;
}

#external-site-back-button::before {
  content: "<";
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--rnib-blue);
  width: 3rem;
  border: none;
}

#external-site-confirm-button::before {
  content: "✓";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  background-color: var(--rnib-pink);
  width: 3rem;
  border: none;
}

.ui>div {
  display: none;
}

.ui>.active {
  display: flex !important;
}

#gui {
  display: none;
  align-items: center;
  flex-direction: column;
  position: fixed;
  padding: 2rem;
  top: 0;
  left: 0;
}

#gui button.cta {
  width: 25rem;
}

#gui button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rnib-pink);
  font-size: 2rem;
  color: white;
  text-align: center;
  padding: 0.5rem;
}



.ui.headset-mode #gui {
  display: flex;
}

.ui.headset-mode {
  position: absolute;
  z-index: -10;
}

.ui.headset-mode>div {
  position: absolute;
  top: 0;
  left: 0;
  color: black;
}

.ui.headset-mode .eye-conditions-grid,
.ui.headset-mode .task-mode-grid {
  background-color: var(--rnib-transparent-grey);
}

.tooltip {
  max-width: 250px;
  background-color: white;
  border-left: 30px solid var(--rnib-pink);
  color: black;
  padding: 0.5rem;
  margin: 0.8rem;
  font-weight: 700;
  position: relative;
}

#tooltip-expand-button {
  content: "i";
  color: #fff;
  background-color: var(--rnib-pink);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  z-index: 6;
  position: absolute;
  bottom: 0;
  width: 2.5rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(50%, 50%); */
}

.tooltip.unclickable::after {
  display: none;
}

.tooltip.expanded-info::after {
  display: none !important;
}

.tooltip.taskMode {
  border-left: 20px solid var(--rnib-blue);
}


#tooltip-main-button.hidden+#tooltip-expand-button,
#tooltip-main-button.expanded-info+#tooltip-expand-button,
#tooltip-main-button.unclickable+#tooltip-expand-button {
  display: none;
}

.tooltip.hidden {
  visibility: hidden;
}

.tooltip-expanded-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rnib-expanded-info span {
  display: block;
  max-width: 700px;
  background-color: white;
  font-weight: 700;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 2rem;
}

.rnib-expanded-info.hidden {
  display: none;
}

.collapse-info {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  line-height: 1rem;
  padding: 1.5rem;
  transform: rotate(-90deg);
  background-color: var(--rnib-pink);
  position: absolute;
}

.eye-conditions-grid,
.task-mode-grid {
  display: flex;
  overflow: hidden;
}

.task-mode-grid.hidden {
  display: none;
}

.eye-conditions-grid div,
.task-mode-grid div {
  display: grid;
  flex-shrink: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
}

/* #task-mode__continue-button {
  justify-content: space-between;
  margin: auto;
  max-width: 10rem;
  grid-column: span 2;
} */

/* #task-mode__yes-button,
#task-mode__no-button {
  justify-content: space-between;
} */

#main {
  background-color: transparent;
  box-sizing: border-box;
  height: 100%;
  justify-content: space-between;
  color: black;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  flex-direction: column-reverse;
  top: 0;
  padding: 2rem;
}

#main.space-between {
  justify-content: space-between;
}

#main.center {
  justify-content: center;
}

#main.end {
  justify-content: end;
}

.eye-conditions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eye-conditions-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem;
}

.eye-conditions-container.hidden {
  display: none;
}

.severity-scale-inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-direction: flex-start;
  background-color: var(--rnib-transparent-grey);
  /* gap: 1rem; */
}

#severity-scale-container.hidden,
.severity-scale__header.hidden,
.severity-scale__footer.hidden,
.color-blindness-grid.hidden,
.severity-scale.hidden {
  display: none;
}

.severity-scale__footer {
  display: flex;
  width: 80%;
  justify-self: center;
  align-self: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0rem;
  font-weight: bold;
  padding-bottom: 1rem;
  font-weight: normal;
}

severity-scale__header::before {
  width: 1.5rem;
}

severity-scale__header {
  display: flex;
  font-weight: bold;
  padding-right: 1rem;
}

.color-blindness-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem;
  gap: 1rem;
}


.left-tab {
  display: flex;
  gap: 1rem;
}

.left-tab::before {
  content: "";
  height: auto;
  width: 2rem;
  background-color: var(--rnib-pink);
  display: block;
}

.severity-scale {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 80%;
  align-self: center;
  padding-top: 1rem;
  padding-bottom: 0rem;
}

.severity-scale li {
  list-style-type: none;
}

.severity-scale li button {
  font-size: 1.5rem;
  list-style-type: none;
  background-color: var(--rnib-pink);
  color: white;
  font-weight: bold;

  padding: 0.5rem 0.8rem;
}

.color-blindness-grid button {
  text-transform: capitalize;
  font-size: 1.2rem;
  list-style-type: none;
  background-color: var(--rnib-pink);
  color: white;
  font-weight: bold;
  padding: 0.5rem 0.8rem;
}

.ui.headset-mode>#homepage {
  position: absolute;
  z-index: -105;
}

.ui.headset-mode>#main {
  position: absolute;
}

/* #main {
    align-items: center;
    color: black;
    top: 0;
    left: 0;
    margin: 1rem;
    width: fit-content;
  } */

/* #main.headset-mode {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    transform: translateY(-5000px);
  } */

.navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 250px;
  width: 110%;
  margin-left: -25px;
}

.navigation--arrow.left {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid #1a1a1a;
  background: transparent;
}

.navigation--arrow.right {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 40px solid #1a1a1a;
  background: transparent;
}

.navigation--arrow:disabled {
  pointer-events: none;
  opacity: 20%;
}

.eye-conditions-grid .cta,
.task-mode-grid .cta {
  font-size: 0.8rem;
  height: 8rem;
  margin: 0.5rem;
  background-color: var(--rnib-pink);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.cta {
  font-weight: 700;
  color: white
}



.navigation--arrow .right {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-radius: 0;

  border-left: 60px solid #1a1a1a;
}

#canvas-texture {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  max-height: 1vh;
  overflow: hidden;
}

button:hover {
  border-color: var(--rnib-blue);
}

/*
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}
*/

.dg.ac,
.stats_modal_container>div {
  display: none;
  padding: 1rem 2rem;
}

.handPoint {
  animation-name: handPointAnim;
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-direction: forwards;
  animation-iteration-count: infinite;
}

@keyframes handPointAnim {
  0% {
    margin-left: 0vw;
    margin-top: 0vw;
  }

  50% {
    margin-left: 15%;
    margin-top: 15%;
  }

  100% {
    margin-left: 0vw;
    margin-top: 0vw;
  }
}

@media screen and (orientation: landscape) {

  /* Debug for Headset Mode */
  .dg.ac,
  .stats_modal_container>div {
    display: none;
  }

  .button {
    min-width: 95px;
  }

  .stats_modal_container {
    display: block;
  }

  .introduction__body {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 62vh;
  }

  .introduction__button-container {
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 1rem;
  }

  .introduction__button-container .button {
    width: 10rem;
  }

  .introduction__body_information div {
    position: absolute;
    max-width: 40vw;
  }

  .introduction_img-container {
    margin-top: 0;
    margin-left: auto;
  }

  .introduction__body img {
    object-fit: contain;
    max-height: 50vh;
    padding-top: 0;
    transform: translateX(36px);
    object-fit: contain;
  }

  .ui {
    display: block;
    width: fit-content;
    pointer-events: none;
  }

  #homepage {
    max-height: none;
    width: fit-content;
    height: unset;
    padding: 0;
  }

  .main .home__button-container {
    padding: 0 5rem;
  }




  #homepage .b,
  #homepage .c {
    display: flex;
  }

  #main {
    flex-direction: column;
    justify-content: space-between;
    width: 1000px;
    height: 44rem;
    margin: 1rem;
  }

  .navigation--arrow.left,
  .navigation--arrow.right {
    font-size: 3rem;
    font-weight: bold;
    background-color: #1a1a1a;
    color: white;
    border-radius: 50%;
    padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    border: none;
  }

  .navigation--arrow.left::before {
    content: "<";
    display: block;
  }

  .navigation--arrow.right::after {
    content: ">";
    display: block;
  }

  .rnib-expanded-info {
    min-width: 500px;
  }

  #homepage {
    margin: 0;
  }

  .tooltip {
    font-size: 1.7rem;
    padding: 0.5rem 2rem;
    max-width: 500px;
  }

  /* #floating-severity-scale {
    position: relative;
    height: 80px;
    width: 291;
  } */


  .eye-conditions-container.hidden {
    display: none;
  }




  .eye-conditions-grid div {
    display: grid;
    grid-template-columns: repeat(3, 230px);
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;
    height: 100%;
  }

  /* Task Mode */

  #myAudio {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
  }

  .task-mode-grid div {
    display: grid;
    grid-template-columns: repeat(2, 253px);
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;
    height: 100%;
  }

  .task-mode__subtitles {
    display: none;
    /* font-family: Arial; */
    font-weight: bold;
    width: 100%;
    color: white;
    -webkit-text-stroke: 1.2px black;
    filter: drop-shadow(0 2.5px 0 black);
    text-align: center;
    justify-content: space-around;
    font-size: 1.5rem;
  }

  .task-mode__subtitles.active {
    display: flex;

  }

  .severity-scale li button {
    padding: 0.7rem 1rem;
  }

  #severity-scale-container {
    display: flex;
    width: 24rem;
    align-items: center;
    justify-content: center;
    background-attachment: red;

  }

  .navigation--arrow {
    margin: 2rem;
    width: 2rem;
    height: 2rem;
  }

  .eye-conditions-grid .cta,
  .task-mode-grid .cta {
    justify-content: start;
    align-items: start;
    padding: 1rem;
    font-size: 1rem;
    height: 10rem;
  }
}

@media screen and (orientation: portrait) {

  /* .task-mode__subtitles,
  .task-mode__subtitles.active {
    display: none !important;
  } */

  .introduction__body {
    min-height: 65vh;
  }

  .introduction__button-container {
    margin-top: auto;
    justify-content: end;
  }

  #continue-button {
    width: 8rem;
  }

  .home__button-container .button::after {
    width: 1rem !important;
    height: 1rem !important;
    margin-right: 0 !important;
  }

  #task-mode__no-button::after {
    width: auto;
    height: auto;
  }

  .tooltip-expanded-container {
    width: 100%;
  }

  .tooltip {
    width: 100%;
  }

  .tooltip-expand-button {
    transform: translate(50%, -50%);
  }

  .rnib-expanded-info {
    margin: 1rem;
  }

  .rnib-expanded-info span {
    max-height: 80vh;
    overflow: scroll;
  }



  #homepage-grid .d {
    background-position: 163% 63%;
  }

  #main {
    padding: 0;
  }

  .eye-conditions-grid .cta {
    text-align: center;
    padding: 0.5rem;
  }

  #external-site {
    margin: 0;
  }

  #external-site main {
    margin: 1rem;
  }

  #main-ui-eye-condition-button {
    position: relative;
  }

  #main-ui-eye-condition-button::after {
    position: absolute;
  }

  #main-ui-eye-condition-button::after {
    right: 0.5rem;
  }
}

.button-icon {
  width: 30px;
  height: 30px;
  /* background-color: orange; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 1.5em;
}

.button-icon[data-icon="skip"] {
  background-image: url("../public/assets/svg/SkipIcon.svg");
}

.button-icon[data-icon="continue"] {
  background-image: url("../public/assets/images/ContinueIconWhite.png");
}

.button-icon[data-icon="recenter"] {
  background-image: url("../public/assets/svg/RecentreARIcon.svg");
}

.button-icon[data-icon="donate"] {
  background-image: url("../public/assets/images/HeartIcon.png");
}

.button-icon[data-icon="sound-on"] {
  background-image: url("../public/assets/svg/SoundOnIcon.svg");
}

.button-icon[data-icon="sound-off"] {
  background-image: url("../public/assets/svg/SoundOffIcon.svg");
}

.button-icon[data-icon="explore"] {
  background-image: url("../public/assets/svg/Magnifier.svg");
}

.button-icon[data-icon="home"] {
  background-image: url("../public/assets/svg/HomeIcon.png");
}

.button-icon[data-icon="scenario"] {
  background-image: url("../public/assets/svg/ScenarioIcon.svg");
}

.button-icon[data-icon="eye"] {
  background-image: url("../public/assets/svg/EyeIcon.svg");
}

.button-icon[data-icon="replay"] {
  background-image: url("../public/assets/svg/ReplayIcon.svg");
}

.button-icon[data-icon="tick"] {
  background-image: url("../public/assets/svg/ConfirmIcon.svg");
}

.button-icon[data-icon="cross"] {
  background-image: url("../public/assets/svg/closeIcon.svg");
}


#eye-seperation-tooltip {
  width: 65%;
  font-size: 1.1rem;
  line-height: 1.2rem;

}

#tutorial {
  width: 350px;
  padding: 1rem;
  font-size: 1.2rem;
}

#gaze-control-tooltip {
  width: 85%;
  padding: 1rem;
  font-size: 1.2rem;
}

.button-recenter h4 {
  font-size: 1.8rem;
}

.main_footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.severity-scale button.selected {
  background-color: var(--rnib-darkPink)
}